import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import '../../styles/template.css';
import ActionButton from '../../components/ActionButton';
import BlockQuote from '../../components/BlockQuote';
import { auth } from '../../api/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Layout from '../../components/Layout';
import { ACCOUNT_DELETION_REASONS } from '../../utils/racqy';
import { deleteUserAccount } from '../../api/auth';

const Profile = ({ location }) => {
  const [user, setUser] = useState(false);

  const [displayName, setDisplayName] = useState(user ? user.displayName : '');
  const [email, setEmail] = useState(user ? user.displayName : '');
  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');
  const [hydrated, setHydrated] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    // Detect if a user is signed in or not
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);

        if (!hydrated) {
          setDisplayName(user.displayName || '');
          setEmail(user.email || '');
          setHydrated(true);
        }

        // Add Authorization to the API-client
        // user.getIdToken()
        //     .then((token) => {
        //         client.defaults.headers['Authorization'] = token
        //         // Make request for user info
        //         return client.get('v1/user-info')
        //     })
        //     .then((data) => {
        //         console.log('data from API', data)
        //     })
      } else {
        // User is signed out
        setUser(false);
        navigate('/');
      }
    });
  }, []);

  const onSubmit = async () => {
    try {
      if (
        window.confirm('Are you sure you want to delete your account?') == true
      ) {
        deleteUserAccount(user ? user.email : '', password, reason)
          .then((res) => {
            setError('');
            auth.signOut().then((res) => {
              alert(
                'Your account will be deleted in 30 days. In case you want to keep your account, you can login to your account using your credentials.'
              );
            });
            //
          })
          .catch((err) => {
            setError(err.response.data.message);
          });
      } else {
        //
      }
    } catch (e) {}
  };

  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - Account Management</title>
      </Helmet>
      <main id="profile" className="mt-[60px] py-16">
        <div className="w-full max-w-[1100px] mx-auto">
          <div className="flex flex-col space-y-12">
            {/* Profile settings */}
            <div className="px-16 py-12 bg-racqy-settings-panel rounded-3xl">
              <div className="w-full max-w-4xl space-y-6">
                <h2 className="text-racqy-text text-2xl sm:text-3xl leading-[1.375]">
                  Delete your account
                </h2>
                <div className="border-b divide-y divide-racqy-divider border-racqy-divider">
                  <div className="flex flex-row items-center">
                    <label
                      htmlFor="displayName"
                      className="w-1/2 text-base sm:text-xl text-racqy-settings-label"
                    >
                      Why do you want to delete your account? *
                    </label>
                    <select
                      className="block w-1/2 py-5 text-base bg-white border-white px-7 focus:border-racqy-blue focus:ring-racqy-blue sm:text-xl"
                      name="sport"
                      id="sports"
                      placeholder="Sport"
                      value={reason}
                      onChange={(evt) => setReason(evt.target.value)}
                    >
                      {['', ...ACCOUNT_DELETION_REASONS].map(
                        (reason, index) => (
                          <option value={reason} key={`sport-${index}`}>
                            {reason}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="flex flex-row items-center">
                    <label
                      htmlFor="email"
                      className="w-1/2 text-base sm:text-xl text-racqy-settings-label"
                    >
                      Password *
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="block w-1/2 py-5 text-base bg-white border-white px-7 focus:border-racqy-blue focus:ring-racqy-blue sm:text-xl"
                      readOnly={false}
                    />
                  </div>
                </div>

                {error && (
                  <BlockQuote type="error">
                    <p>{error}</p>
                  </BlockQuote>
                )}

                {/* {editingProfile &&
                                    <div className="flex space-x-6">
                                    <ActionButton
                                            title="Cancel"
                                            tint="light"
                                            onClick={() => cancelEditingProfile()}
                                        > 
                                        </ActionButton>
                                        <ActionButton
                                            title={savingProfile ? 'Saving changes...' : 'Save changes' }
                                            tint="blue"
                                            // onClick={() => saveProfile()}
                                            loading={savingProfile}
                                        >
                                        </ActionButton>
                                    </div>
                                } */}
                <div>
                  <ActionButton
                    title="Submit"
                    tint="light"
                    disabled={!reason || password.length < 6}
                    onClick={onSubmit}
                  ></ActionButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Profile;
